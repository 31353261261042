import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 401.000000 306.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
         transform="translate(0.000000,306.000000) scale(0.100000,-0.100000)"
        >
          <path d="M1840 1947 c0 -7 19 -32 43 -55 l43 -42 207 0 c267 0 262 4 255 -195
-4 -151 5 -145 -249 -145 l-199 0 -45 -41 c-25 -23 -45 -48 -45 -55 0 -12 55
-14 320 -14 l320 0 32 29 33 29 3 206 c3 227 -2 252 -58 281 -40 21 -660 23
-660 2z"/>
<path d="M1830 1715 l0 -95 -190 0 -190 0 0 -100 0 -100 190 0 190 0 0 95 0
95 185 0 185 0 0 100 0 100 -185 0 -185 0 0 -95z"/>
<path d="M1222 1198 l3 -93 54 0 c46 1 57 5 77 29 34 39 32 89 -5 127 -25 24
-37 29 -81 29 l-51 0 3 -92z m112 40 c33 -46 4 -108 -49 -108 -24 0 -25 2 -25
65 l0 65 29 0 c20 0 35 -8 45 -22z"/>
<path d="M2100 1201 c0 -59 4 -92 12 -97 6 -4 14 -2 16 4 2 8 10 8 28 0 53
-25 99 33 71 90 -11 23 -19 27 -55 26 -41 -1 -42 0 -42 33 0 23 -5 33 -15 33
-12 0 -15 -17 -15 -89z m94 -7 c27 -27 16 -64 -20 -64 -19 0 -44 24 -44 44 0
8 31 36 40 36 5 0 16 -7 24 -16z"/>
<path d="M2356 1274 c-18 -18 -21 -44 -5 -44 6 0 18 9 27 21 22 26 52 16 52
-18 0 -18 -14 -38 -45 -66 -63 -57 -60 -69 17 -65 38 2 63 8 66 16 2 8 -9 12
-39 12 l-43 0 37 38 c60 62 47 122 -27 122 -13 0 -32 -7 -40 -16z"/>
<path d="M2532 1227 c-23 -34 -42 -68 -42 -75 0 -8 17 -12 50 -12 43 0 50 -3
50 -20 0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 7 20 15 20 8 0 15 7 15 15 0 8
-7 15 -15 15 -12 0 -15 13 -15 60 0 82 -21 81 -78 -3z m55 -54 c-3 -2 -17 -3
-31 -1 l-26 3 27 40 28 40 3 -39 c2 -21 1 -40 -1 -43z"/>
<path d="M2650 1275 c0 -11 12 -15 50 -15 28 0 50 -3 50 -6 0 -3 -13 -36 -30
-74 -34 -78 -35 -80 -16 -80 11 0 87 159 86 183 0 4 -31 7 -70 7 -56 0 -70 -3
-70 -15z"/>
<path d="M1580 1250 c0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -8 5 -15 10
-15 6 0 10 -22 10 -50 0 -38 4 -50 15 -50 11 0 15 12 15 49 0 34 4 51 15 55 8
3 15 10 15 16 0 5 -7 10 -15 10 -8 0 -15 9 -15 20 0 11 -7 20 -15 20 -8 0 -15
-9 -15 -20z"/>
<path d="M1430 1215 c-17 -21 -7 -29 18 -14 21 13 52 7 52 -11 0 -5 -11 -10
-24 -10 -34 0 -69 -31 -61 -53 9 -22 51 -33 70 -17 10 9 15 9 15 1 0 -6 7 -11
15 -11 21 0 21 93 -1 114 -20 20 -68 21 -84 1z m68 -67 c-7 -21 -36 -34 -49
-21 -6 6 -8 16 -5 22 10 15 59 14 54 -1z"/>
<path d="M1674 1213 c-12 -12 -13 -18 -4 -21 6 -2 17 1 23 7 16 16 47 14 47
-4 0 -8 -9 -15 -21 -15 -51 0 -77 -42 -43 -71 12 -10 22 -10 45 -2 16 6 29 7
29 2 0 -5 5 -9 10 -9 14 0 12 104 -2 118 -17 17 -64 15 -84 -5z m66 -68 c0 -9
-7 -18 -16 -22 -18 -7 -39 11 -30 26 11 17 46 13 46 -4z"/>
<path d="M1822 1218 c-23 -23 -13 -46 23 -58 19 -6 35 -16 35 -21 0 -16 -29
-22 -45 -9 -20 17 -32 5 -15 -15 16 -20 54 -19 74 1 26 26 16 49 -27 59 -15 4
-27 13 -27 21 0 17 22 18 38 2 15 -15 26 -4 19 17 -8 18 -58 20 -75 3z"/>
<path d="M1940 1193 c0 -21 3 -48 6 -61 7 -26 48 -40 68 -23 10 8 16 9 21 1
16 -25 25 -5 25 55 0 50 -3 65 -14 65 -11 0 -16 -14 -18 -47 -3 -44 -5 -48
-28 -48 -23 0 -25 4 -28 48 -2 33 -7 47 -18 47 -10 0 -14 -11 -14 -37z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
